import { Add, Visibility } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Box } from '@mui/system';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';
import S3DownloadLink from 'components/molecules/s3DownloadLink';
import Config from 'config';
import { dateFormatter, titleCase } from 'helpers/dateChanger';
import StringHelper from 'helpers/stringHelper';
import Proptypes from 'prop-types';
import { useState } from 'react';
import { Link, Link as RouterLink, useLocation } from 'react-router-dom';
import { formatTodayDistance } from 'services/DateTime';

const PlatformMap = {
  [Config.GOINVEST_CLIENT_ID]: 'Go Invest',
  [Config.PLUANG_CLIENT_ID]: 'Pluang',
  [Config.PLUANG_WEB_CLIENT_ID]: 'Pluang Web'
};

const PluangTable = ({
  headers,
  totalElements,
  data,
  activePage,
  pageSize,
  roles,
  totalPages,
  handlePageChange,
  sendMail
}) => {
  const [plaungTableState, setPluangTableState] = useState({
    color: 'black',
    sortIcon: '▼',
    isAscending: true
  });

  const location = useLocation();

  const sortTable = (data, header) => {
    const { isAscending } = plaungTableState;
    let sortedData = data;
    sortedData.sort((a, b) => {
      if (!isNaN(a[header.db_column_name])) {
        if (isAscending) {
          setPluangTableState((prevState) => ({
            ...prevState,
            isAscending: false
          }));
          return a[header.db_column_name] - b[header.db_column_name];
        } else {
          setPluangTableState((prevState) => ({
            ...prevState,
            isAscending: true
          }));
          return b[header.db_column_name] - a[header.db_column_name];
        }
      } else if (isAscending) {
        setPluangTableState((prevState) => ({
          ...prevState,
          isAscending: false
        }));
        a[header.db_column_name] = a[header.db_column_name].replace(/^ +/gm, ''); //remove any leading spaces in string
        b[header.db_column_name] = b[header.db_column_name].replace(/^ +/gm, ''); //remove any leading spaces in string
        return a[header.db_column_name].localeCompare(b[header.db_column_name]);
      } else {
        setPluangTableState((prevState) => ({
          ...prevState,
          isAscending: true
        }));
        a[header.db_column_name] = a[header.db_column_name].replace(/^ +/gm, ''); //remove any leading spaces in string
        b[header.db_column_name] = b[header.db_column_name].replace(/^ +/gm, ''); //remove any leading spaces in string
        return b[header.db_column_name].localeCompare(a[header.db_column_name]);
      }
    });
    setPluangTableState((prevState) => ({
      ...prevState,
      data: sortedData
    }));
  };

  const handleColor = (data) => {
    const createdDate = data && data.created;
    const diff = formatTodayDistance(createdDate, 'days');
    const tableName = data && data.table;
    if (tableName && Config.ColorMap[tableName]) {
      return Config.ColorMap[tableName];
    }
    if (data && data.todayCashout && data.todayCashout > 500000000 && diff < 1) {
      return '#F0DBFD';
    } else if (data && data.todayCashout && data.todayCashout > 100000000 && diff < 1) {
      return '#FFA500';
    } else if (data && data.weeklyCashout && data.weeklyCashout > 500000000 && diff < 7) {
      return '#FA426C';
    } else if (data && data.weeklyCashout && data.weeklyCashout > 100000000 && diff < 7) {
      return '#FCFA79';
    } else {
      return 'white';
    }
  };
  return (
    <>
      <TableContainer>
        <Table
          sx={{
            whiteSpace: 'nowrap'
          }}
          size="small">
          <TableHead>
            <TableRow>
              {headers
                .filter((header) => !header.role || roles.indexOf(header.role) > -1)
                .map((header) => (
                  <TableCell
                    sx={{
                      fontWeight: 700
                    }}
                    key={header.db_column_name}>
                    {header.header_name}{' '}
                    {header.isSort ? (
                      <span
                        type="button"
                        style={{ float: 'right' }}
                        onClick={() => sortTable(data, header)}>
                        {' '}
                        {plaungTableState.sortIcon}{' '}
                      </span>
                    ) : null}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {totalElements > 0 ? (
              data.map((row) => (
                <TableRow
                  sx={{
                    '&:nth-of-type(odd)': {
                      backgroundColor: 'rgba(0, 0, 0, 0.05)'
                    },
                    '&:last-child td, &:last-child th': {
                      border: 0
                    }
                  }}
                  key={row.id + (row.orderType ? row.orderType : '')}
                  style={{ backgroundColor: handleColor(row) }}>
                  {headers
                    .filter((header) => !header.role || roles.indexOf(header.role) > -1)
                    .map((header) => {
                      if (header.db_column_name === 'active') {
                        return (
                          <TableCell key={row.id + '_active'}>
                            {row.active === false || row.active === 'false' ? 'Inactive' : 'Active'}
                          </TableCell>
                        );
                      } else if (header.db_column_name === 'status' && header.isDownload) {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            {(row[header.db_column_name] === header.downloadValue &&
                              header.isDownload === true) ||
                            header.isDownload === 'true' ? (
                              <S3DownloadLink url={row[header.downloadKey]} />
                            ) : (
                              row[header.db_column_name]
                            )}
                          </TableCell>
                        );
                      } else if (
                        header.db_column_name === 'missionStatus' ||
                        header.db_column_name === 'actionTableName' ||
                        header.db_column_name === 'rewardTableName'
                      ) {
                        return (
                          <TableCell key={row.id + '_' + header.header_name}>
                            {titleCase(row[header.db_column_name])}
                          </TableCell>
                        );
                      } else if (header.db_column_name === 'sendMail' && header.isSend) {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            {header && header.emailId && row[header.emailId] && row[header.html] ? (
                              <Button
                                sx={{
                                  marginRight: '10px'
                                }}
                                type="button"
                                onClick={() =>
                                  sendMail(
                                    this,
                                    row[header.emailId],
                                    row[header.html],
                                    row[header.id]
                                  )
                                }>
                                Send Mail
                              </Button>
                            ) : (
                              ''
                            )}
                          </TableCell>
                        );
                      } else if (header.isDownload) {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            {row[header.db_column_name] !== null &&
                            row[header.db_column_name] !== '' ? (
                              <S3DownloadLink url={row[header.db_column_name]} />
                            ) : (
                              row[header.db_column_name]
                            )}
                          </TableCell>
                        );
                      } else if (header.roundOff) {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            {row[header.db_column_name].toFixed(2)}
                          </TableCell>
                        );
                      } else if (header.formattedCurrency) {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            {StringHelper.toFormattedCurrency(
                              row[header.db_column_name],
                              header.formatCurrencyOptions || {}
                            )}
                          </TableCell>
                        );
                      } else if (header.db_column_name === 'deliveryAddress') {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            <text>{row[header.db_column_name].substring(0, 60)} </text>
                          </TableCell>
                        );
                      } else if (header.db_column_name === 'bappebtiKycStatus') {
                        return (
                          <TableCell key={row.id + '_row.bappebtiKycStatus'}>
                            {row.bappebtiKycStatus === null ? 'Pending' : row.bappebtiKycStatus}
                          </TableCell>
                        );
                      } else if (
                        header.db_column_name === 'createdAt' ||
                        header.db_column_name === 'updatedAt' ||
                        header.db_column_name === 'expires' ||
                        header.db_column_name === 'transaction_date' ||
                        header.db_column_name === 'created' ||
                        header.db_column_name === 'startTime' ||
                        header.db_column_name === 'endTime' ||
                        header.db_column_name === 'validTo' ||
                        header.db_column_name === 'validFrom' ||
                        header.db_column_name === 'updated' ||
                        header.db_column_name === 'lastUploadTime' ||
                        header.db_column_name === 'kycLastUploadTime' ||
                        header.db_column_name === 'oldestKycLastUploadTime' ||
                        header.db_column_name === 'startDate' ||
                        header.db_column_name === 'endDate' ||
                        header.db_column_name === 'accountCreatedAt' ||
                        header.db_column_name === 'balanceMigratedAt' ||
                        header.formatAsDate
                      ) {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            {dateFormatter(row[header.db_column_name])}
                          </TableCell>
                        );
                      } else if (header.db_column_name === 'clientId') {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            {PlatformMap[row[header.db_column_name]]
                              ? PlatformMap[row[header.db_column_name]]
                              : 'Pluang'}
                          </TableCell>
                        );
                      } else if (header.db_column_name === 'action') {
                        return (
                          <TableCell key={row.id + '_edit'}>
                            {/* show Action button for cashout row only*/}
                            {header.isView &&
                              header.isCashoutTopup &&
                              row.table === Config.CASHOUT_TABLE && (
                                <Link to={`${location.pathname}/view/${row.id}`}>
                                  <Button title="View" type="button">
                                    <Visibility fontSize="small" />
                                  </Button>
                                </Link>
                              )}
                            {header.isView &&
                              !header.isCashoutTopup &&
                              header.isUserId &&
                              !header.isTravelRule && (
                                <Link to={`${location.pathname}/view/${row.userId}`}>
                                  <Button title="View" type="button">
                                    <Visibility fontSize="small" />
                                  </Button>
                                </Link>
                              )}
                            {header.isView && !header.isCashoutTopup && !header.isUserId && (
                              <Link to={`${location.pathname}/view/${row.id}`}>
                                <Button title="View" type="button">
                                  <Visibility fontSize="small" />
                                </Button>
                              </Link>
                            )}
                            {header.isView && header.isTravelRule && (
                              <Link
                                component={RouterLink}
                                state={{ cryptoTransferData: row }}
                                to={`${location.pathname}/view/${row.id}`}>
                                <IconButton title="View" color="primary">
                                  <Visibility />
                                </IconButton>
                              </Link>
                            )}
                            {header.isEdit && (
                              <Link to={`${location.pathname}/edit/${row.id}`}>
                                <Button title="Edit" type="button">
                                  <Visibility fontSize="small" />
                                </Button>
                              </Link>
                            )}
                            {header.isAdd && (
                              <Link to={`${location.pathname}/add/${row.id}`}>
                                <button title="Add" type="button">
                                  <Add fontSize="small" />
                                </button>
                              </Link>
                            )}
                          </TableCell>
                        );
                      } else if (typeof row[header.db_column_name] === 'boolean') {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            {row[header.db_column_name] === false ||
                            row[header.db_column_name] === 'false'
                              ? 'No'
                              : 'Yes'}
                          </TableCell>
                        );
                      } else if (row[header.db_column_name] === 'roles') {
                        return (
                          <TableCell key={row.id + '_' + header.db_column_name}>
                            {row[header.db_column_name].toString()}
                          </TableCell>
                        );
                      } else if (header.isCustom && header.customMethod) {
                        return (
                          <TableCell key={row.id + '_' + header.header_name}>
                            {header.customMethod(row)}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={row.id + '_' + header.db_column_name}>
                          {row[header.db_column_name]}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={headers.length}
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '20px',
                    color: 'red'
                  }}>
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <hr />
      <Container>
        <Item xs={12}>
          {totalElements ? <b>Total Elements: {totalElements}</b> : null}
          <Box
            sx={{
              float: 'right'
            }}>
            {totalElements > pageSize ? (
              <Pagination
                color="primary"
                page={activePage}
                shape="rounded"
                showFirstButton
                showLastButton
                siblingCount={1}
                count={totalPages}
                onChange={(e, v) => handlePageChange(v)}
              />
            ) : null}
          </Box>
        </Item>
      </Container>
    </>
  );
};

PluangTable.propTypes = {
  match: Proptypes.object,
  data: Proptypes.array.isRequired,
  headers: Proptypes.array.isRequired,
  totalPages: Proptypes.number,
  totalElements: Proptypes.number,
  pageSize: Proptypes.number.isRequired,
  handlePageChange: Proptypes.func,
  activePage: Proptypes.number,
  roles: Proptypes.array,
  sendMail: Proptypes.func
};

export default PluangTable;
