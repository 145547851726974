import PluangListPage from 'components/organisms/pluangListTable';

const filters = [
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'INITIATED',
    options: [
      { text: 'All', value: '' },
      { text: 'INITIATED', value: 'INITIATED' },
      { text: 'VERIFIED', value: 'VERIFIED' },
      { text: 'COMPLETED', value: 'COMPLETED' },
      { text: 'RECHECK', value: 'RECHECK' },
      { text: 'FAILED', value: 'FAILED' },
      { text: 'EXPIRED', value: 'EXPIRED' }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

const headers = [
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'TopUp Amount', db_column_name: 'topUpAmount' },
  { header_name: 'Created At', db_column_name: 'created' },
  { header_name: 'Last Updated', db_column_name: 'updated' },
  { header_name: 'Action', db_column_name: 'action', isView: true }
];

const ForexTopupList = () => {
  return (
    <PluangListPage
      title="Forex Topups"
      headers={headers}
      filters={filters}
      filterDateRange={14}
      resource="USD_TOPUP"
    />
  );
};

export default ForexTopupList;
